<script>
import aclModuleClient from '@/core/acl-module-client';

export default {
  name: 'AclUserDetails',
  data: () => ({
    user: {
      uuid: null,
      loading: true,
      syncing: false,
      data: {},
    },
    editingUser: {
      loading: false,
      data: {
        name: '',
        first_name: '',
        last_name: '',
      },
    },
    user_roles: {
      loading: true,
      data: [],
      fields: [
        { key: 'group_title', label: 'Group' },
        { key: 'region_code', label: 'Region' },
        { key: 'app_name', label: 'Application' },
        { key: 'title', label: 'Name' },
        { key: 'actions', label: '' },
      ],
    },
    apps: {
      loading: true,
      data: {},
    },
    user_groups: {
      loading: true,
      data: [],
      fields: [
        { key: 'title', label: 'Group' },
        { key: 'region_code', label: 'Region' },
        { key: 'active', label: 'Active' },
        { key: 'actions', label: '' },
      ],
    },
    groups: {
      loading: true,
      data: [],
    },
    regions: {
      loading: true,
      data: [],
    },
    regionsByApp: {
      loading: false,
      items: [],
    },
    new_roles: {
      loading: false,
      items: [],
      fields: [
        { key: 'role_title', label: 'Role' },
        { key: 'region_code', label: 'Region' },
        { key: 'actions', label: '' },
      ],
    },
    roles: {
      loading: true,
      items: [],
    },
    application_details: {
      selected: false,
      region: null,
      app: null,
      roles: [],
    },
    config: {
      message: () => {},
    },
    configs: {
      active: {
        type: 'active',
        endpoint: 'status',
        message: value => `Would you like ${value ? 'activate' : 'inactivate'} this user?`,
      },
      strict_data_access: {
        type: 'strict_data_access',
        endpoint: 'data-level',
        message: value => `Would you like ${value ? 'add' : 'remove'} strict data access for this user?`,
      },
    },
  }),
  beforeMount() {
    this.user.uuid = this.$route.params.uuid;
    this.fetchUser();
    this.fetchUserRoles();
    this.fetchUserGroups();
    this.fetchApps();
    this.fetchRegions();
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', event => {
      if (event.componentId === 'modal-edit-user') {
        this.editingUser.data.name = this.user.data.name;
        this.editingUser.data.first_name = this.user.data.first_name;
        this.editingUser.data.last_name = this.user.data.last_name;
      }
    });
  },
  computed: {
    userApps() {
      const apps = {};
      this.user_roles.data.forEach(role => {
        const key = `${role.region_code}-${role.app_name}`;
        if (!apps[key]) {
          apps[key] = {
            region_code: role.region_code,
            app_name: role.app_name,
            roles: [],
          };
        }
        apps[key].roles.push(role.title);
      });
      return apps;
    },
    syncing() {
      return this.user.syncing || this.user.loading;
    },
  },
  methods: {
    fetchApps() {
      this.apps.loading = true;
      return aclModuleClient.get('v1/apps')
        .then(response => {
          this.apps.data = response.data;
        })
        .catch(e => {
          this.$noty.error('Error loading apps');
          console.error(e);
        })
        .finally(() => {
          this.apps.loading = false;
        });
    },
    fetchRegions() {
      this.regions.loading = true;
      return aclModuleClient.get('v1/regions')
        .then(response => {
          this.regions.data = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading regions');
          console.error(e);
        })
        .finally(() => {
          this.regions.loading = false;
        });
    },
    fetchUser() {
      this.user.loading = true;
      return aclModuleClient.get(`v1/users/${this.user.uuid}`)
        .then(response => {
          this.user.data = response.data;
          this.user.data.active = response.data.active === 1;
          this.user.data.strict_data_access = response.data.strict_data_access === 1;
          this.editingUser.data.name = this.user.data.name;
          this.editingUser.data.first_name = this.user.data.first_name;
          this.editingUser.data.last_name = this.user.data.last_name;
        })
        .catch(e => {
          this.$noty.error('Error loading user');
          console.error(e);
        })
        .finally(() => {
          this.user.loading = false;
        });
    },
    fetchUserRoles() {
      this.user_roles.loading = true;
      return aclModuleClient.get(`v1/users/${this.user.uuid}/roles`)
        .then(response => {
          this.user_roles.data = response.data;
        })
        .catch(e => {
          this.$noty.error('Error loading user roles');
          console.error(e);
        })
        .finally(() => {
          this.user_roles.loading = false;
        });
    },
    fetchUserGroups() {
      this.user_groups.loading = true;
      return aclModuleClient.get(`v1/users/${this.user.uuid}/groups`)
        .then(response => {
          this.user_groups.data = response.data;
        })
        .catch(e => {
          this.$noty.error('Error loading user roles');
          console.error(e);
        })
        .finally(() => {
          this.user_groups.loading = false;
        });
    },
    compareRole(item, roleId, regionId) {
      return item.role_id === roleId && item.region_id === regionId;
    },
    updateRoles(roles) {
      this.new_roles.loading = true;
      return aclModuleClient.put(`v1/users/${this.user.data.uuid}/roles`, roles)
        .then(() => {
          this.$noty.success('Roles updated to user');
          this.new_roles.items = [];
        })
        .catch(error => {
          this.$noty.error(`Error updating user to roles:<br/>${error.message}`);
          console.error(error);
        })
        .finally(() => {
          this.new_roles.loading = false;
          this.fetchUserRoles();
        });
    },
    deleteGroup(title, groupId, regionId) {
      return this
        .$bvModal
        .msgBoxConfirm(`Remove user from group ${title}`, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return null;
          }

          return aclModuleClient
            .delete(`v1/users/${this.user.data.uuid}/groups/${groupId}/regions/${regionId}`);
        })
        .then(hasRemoved => {
          if (hasRemoved) {
            this.$noty.success('User has been removed from group');
            this.fetchUserGroups();
            this.fetchUserRoles();
          }
        })
        .catch(error => {
          this.$noty.error(`Error trying to remove user from group: ${error.message}`);
          console.error(error);
        });
    },
    deleteRole(roleId, regionId, title) {
      return this.$bvModal.msgBoxConfirm(`Remove role ${title}`, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }

          const filteredRoles = this.user_roles.data
            .filter(item => !this.compareRole(item, roleId, regionId) && !item.group_id)
            .map(item => ({
              role_id: item.role_id,
              region_id: item.region_id,
            }));

          this.updateRoles(filteredRoles);
        })
        .catch(() => {
          this.$noty.error('Cannot open confirmation dialog');
        });
    },
    setApplicationDetails(region, app, roles) {
      this.application_details.selected = true;
      this.application_details.region = region;
      this.application_details.app = app;
      this.application_details.roles = roles;
    },
    syncUsers() {
      this.user.syncing = true;
      aclModuleClient.post(`v1/users/${this.user.data.uuid}/sync`)
        .then(() => {
          this.$noty.success('User synced');
        })
        .catch(e => {
          this.$noty.error('Error syncing user');
          console.error(e);
        })
        .finally(() => {
          this.user.syncing = false;
        });
    },
    goToEditRoles() {
      this.$router.push(`/acl/users/${this.user.uuid}/roles`);
    },
    goToEditGroups() {
      this.$router.push(`/acl/users/${this.user.uuid}/groups`);
    },
    confirmUpdateUser() {
      this.editingUser.loading = true;
      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      return aclModuleClient
        .put(`v1/users/${this.user.uuid}`, this.editingUser.data)
        .then(() => this.fetchUser())
        .then(() => {
          this.$noty.success('User updated successfully');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-edit-user'].hide();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to update user');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          setTimeout(() => {
            this.editingUser.loading = false;
          }, 500);
        });
    },
    confirmUpdateUserConfigs() {
      this.editingUser.loading = true;
      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      const data = {
        [this.config.type]: this.config.value ? 1 : 0,
      };

      return aclModuleClient
        .patch(`v1/users/${this.user.uuid}/${this.config.endpoint}`, data)
        .then(() => this.fetchUser())
        .then(() => {
          this.$noty.success('User updated successfully');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$bvModal.hide('modal-config');
          this.resetConfig();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to update user');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$bvModal.hide('modal-config');
        })
        .finally(() => {
          setTimeout(() => {
            this.editingUser.loading = false;
          }, 500);
        });
    },
    handleChangeConfig(type, value) {
      this.config = this.configs[type];
      this.config.value = value;
      this.$bvModal.show('modal-config');
    },
    hideModalConfig() {
      this.user.data[this.config.type] = !this.config.value;
      this.$bvModal.hide('modal-config');
      this.resetConfig();
    },
    resetConfig() {
      this.config = {
        message: () => {},
      };
    },
  },
};
</script>

<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="mb-2 d-flex align-items-center justify-content-between">
        <h4>ACL User details - {{ user.data.name }}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-2 d-flex flex-row-reverse">
        <b-button variant="primary" class="float-right" :disabled="syncing" @click="syncUsers">
          <b-spinner v-if="syncing" type="border" small></b-spinner>
          <feather v-else type="refresh-cw"></feather> Sync
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="h6 m-0 align-content-center">
              User
            </b-card-title>

            <div>
              <b-button
                variant="primary"
                size="sm"
                :disabled="user.loading"
                v-b-modal.modal-edit-user>
                Edit User
                <b-spinner v-if="user.loading" class="align-middle" small />
                <feather v-else type="edit" />
              </b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled">
              <li>
                <b>uuid:</b> {{ user.data.uuid }}
              </li>
              <li>
                <b>E-mail:</b> {{ user.data.email }}
              </li>
              <li>
                <b>Name:</b> {{ user.data.name }}
              </li>
              <li>
                <b>First Name:</b> {{ user.data.first_name }}
              </li>
              <li>
                <b>Last Name:</b> {{ user.data.last_name }}
              </li>
              <li>
                <b>Created At:</b> {{ user.data.created_at }}
              </li>
              <li>
                <b>Updated At:</b> {{ user.data.updated_at }}
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <b class="mr-1">Active:</b>
                  <b-form-checkbox
                    @change="handleChangeConfig('active', user.data.active)"
                    v-model="user.data.active"
                    switch />
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <b class="mr-1">Strict Data Access:</b>
                  <b-form-checkbox
                    @change="handleChangeConfig('strict_data_access', user.data.strict_data_access)"
                    v-model="user.data.strict_data_access"
                    switch />
                </div>
              </li>
            </ul>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-row>
          <b-col cols="3">
            <b-card header="Applications">
              <ul class="list-unstyled">
                <li v-for="(app, key) in userApps" :key="`user-apps-${key}`" @click="setApplicationDetails(app.region_code, app.app_name, app.roles)">
                  <b-badge role="button" variant="primary" class="mr-1">{{ app.region_code }}</b-badge>
                  <b-badge role="button" variant="success">{{ app.app_name }}</b-badge>
                </li>
              </ul>
            </b-card>
          </b-col>
          <b-col cols="9">
            <b-card header="Application Roles">
              <template v-if="application_details.selected === false">
                select an app to see details.
              </template>
              <template v-else>
                <div>
                  <b-badge variant="primary" class="mr-1">{{ application_details.region }}</b-badge>
                  <b-badge variant="success">{{ application_details.app }}</b-badge>
                </div>
                <ul class="mt-3">
                  <li v-for="(role, index) in application_details.roles"
                      :key="`app-role-${application_details.region}-${application_details.app}-${index}`">{{role}}</li>
                </ul>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="5">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="h6 m-0 align-content-center">
              User groups
            </b-card-title>

            <div>
              <b-button
                size="sm"
                variant="primary"
                title="Edit user's groups"
                v-b-tooltip:hover
                @click.prevent="goToEditGroups">
                Edit groups
                <feather type="users"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-table :fields="user_groups.fields" :items="user_groups.data" :busy="user_groups.loading"
                     sticky-header="800px" striped empty-text="No user specific roles">
              <template #cell(active)="data">
                <b-badge variant="success" v-if="data.item.active">Active</b-badge>
                <b-badge variant="danger" v-else>Inactive</b-badge>
              </template>
              <template #cell(actions)="data">
                <b-badge class="mr-3 float-right" pill variant="danger" role="button"
                         @click="deleteGroup(data.item.title, data.item.id, data.item.region_id)">
                  <feather type="x-square"/>
                </b-badge>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="7">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="h6 m-0 align-content-center">
              User specific roles
            </b-card-title>

            <div>
              <b-button
                size="sm"
                variant="primary"
                title="Edit user's roles"
                v-b-tooltip:hover
                @click.prevent="goToEditRoles">
                Edit roles
                <feather type="sliders"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-table :fields="user_roles.fields" :items="user_roles.data" :busy="user_roles.loading"
                     sticky-header="800px" striped empty-text="No user specific roles">
              <template #cell(actions)="data">
                <b-badge v-if="!data.item.group_id" variant="danger" role="button"
                         @click="deleteRole(data.item.role_id, data.item.region_id, data.item.title)">
                  <feather type="x-square"/>
                </b-badge>
              </template>
            </b-table>
          </b-card-body>
        </b-card>

      </b-col>
    </b-row>

    <!-- #region Edit user -->
    <b-modal id="modal-edit-user" ref="modal-edit-user" :title="`Edit user - ${user.data.name}`">
      <b-container class="modal-body p-0" fluid>
        <b-form-row>
          <b-col>
            <b-form-group label="Name">
              <b-form-input
                placeholder="Name"
                v-model="editingUser.data.name" />
            </b-form-group>

            <b-form-group label="First Name">
              <b-form-input
                placeholder="First Name"
                v-model="editingUser.data.first_name" />
            </b-form-group>

            <b-form-group label="Last Name">
              <b-form-input
                placeholder="Last Name"
                v-model="editingUser.data.last_name" />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-container>

      <template #modal-footer="{ hide }">
        <b-button variant="secondary" :disabled="editingUser.loading" @click="hide('cancel')">
          Cancel
        </b-button>
        <b-button variant="primary" :disabled="editingUser.loading" @click="confirmUpdateUser">
          Update
        </b-button>
      </template>
    </b-modal>
    <!-- #endregion -->

    <!-- #region Edit user -->
    <b-modal id="modal-config" @hidden="hideModalConfig" ref="modal-edit-user" :title="`Edit user - ${user.data.name}`">
      <b-container class="modal-body p-0" fluid>
        <p>{{ this.config.message(this.config.value) }}</p>
      </b-container>

      <template #modal-footer="{ hide }">
        <b-button variant="secondary" :disabled="editingUser.loading" @click="hideModalConfig">
          Cancel
        </b-button>
        <b-button variant="primary" :disabled="editingUser.loading" @click="confirmUpdateUserConfigs">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- #endregion -->
  </b-container>
</template>
